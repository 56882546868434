@import url('https://fonts.googleapis.com/css?family=Roboto');

* {
  font-family: 'Roboto', sans-serif !important;
}

html {
  font-size: 16px;
}

@media (max-width: 320px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 561px) {
  .content {
    max-width: 560px;
    margin: 0 auto;
  }
}

.logoHeader {
  background: #fff url("./resources/logo_small.svg") center no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  text-align: center;
  width: 72px;
  height: 72px;
  margin-bottom: 2rem;
}

.flexContent.center {
   justify-content: center;
 }
.flexContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1em;
  overflow: auto;
}

.switch {
  display: flex;
  justify-content: center;
  padding: 1em 0.5em;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}
.switch__button {
  background: #ffffff;
  color: #F15A29;
  border: 1px solid #F15A29;
  font-size: 0.875em;
  padding: 0.25em;
  width: 50%;
  word-break: break-word;
}
.switch__button:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.switch__button:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.switch__button.active {
  background: #F15A29;
  color: #ffffff;
}
@media (max-width: 290px) {
  .switch__button {
    font-size: 0.75em;
  }
}
.lockerOpener__row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 0.875em 0;
}
.lockerOpener__list {
   list-style: decimal;
   padding-left: 16px;
 }
.lockerOpener__title, .lockerOpener__title--black, .lockerOpener__title--grey {
  font-size: 1em;
  margin-bottom: 0.5em;
}
.lockerOpener__title--grey {
  color: #808080;
  text-transform: uppercase;
}
.lockerOpener__title--black {
  color: #000000;
}
.lockerOpener__text, .lockerOpener__text--blue, .lockerOpener__text--blueSmall, .lockerOpener__text--dark, .lockerOpener__text--grey {
  font-size: 0.75em;
  line-height: 1.25em;
}
.lockerOpener__text--grey {
  color: #808080;
}
.lockerOpener__text--dark {
  color: #414141;
}
.lockerOpener__text--dark.paid {
  list-style: none;
  position: relative;
  color: #35a518;
}
.lockerOpener__text--dark.paid:before {
  content: "✓";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: calc(50% - 0.5em);
  left: -16px;
  width: 1em;
  height: 1em;
  font-size: 1em;
  font-weight: 700;
  color: #35a518;
  border-radius: 50%;
}
.lockerOpener__text--blueSmall {
  color: #6B78BB;
  font-weight: 700;
}
.lockerOpener__text--blue {
  color: #6B78BB;
}
.lockerOpener__text--blue:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 4px;
  background: url("./resources/phone.svg") no-repeat;
  background-size: contain;
}
.lockerOpener__partner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 1px solid rgba(128, 128, 128, 0.5);
}
.lockerOpener__flexRow, .lockerOpener__flexRow--middlePadding, .lockerOpener__flexRow--smallPadding {
  display: flex;
  align-items: center;
}
.lockerOpener__flexRow--smallPadding {
  padding: 0.25rem 0;
}
.lockerOpener__flexRow--middlePadding {
  padding: 0.5rem 0;
}
